<template>
  <div :class="windowClass">
    <v-container>

     <v-row>
    <v-col cols="12">
      <chart-collection-bar :rawdata="COLLECTIONS" />
    </v-col>
    <v-col cols="12">
      <span class="mr-8">Date Covered: {{this.from_date}} to {{this.to_date}} </span> 
      <span class="text-warning mr-8">No. of Trans: {{TRANSCOUNT}} </span>
      <span class="text-info  ml-5 mr-8">Requested: {{$money(TOTALREQ)}} </span> 
      <span class="text-success  ml-5">Recieved: {{$money(RECIEVABLE)}} </span>
    </v-col>
       <v-col cols="12">
                 <v-card>
                <v-toolbar>
                  <v-icon left color="success">mdi-credit-card</v-icon> Buy Credits Summaries
                  <v-spacer/>
                  <span style="width: 200px; margin-top: 24px;"> 
                     <v-menu
                        v-model="from_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                                label="From Date"
                                 :value="from_date"
                                 filled
                                 readonly
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="from_date" no-title scrollable @input="from_menu=false" />
                    </v-menu>
               </span>
                <span style="width: 200px; margin-top: 24px;" class="ml-2"> 
                     <v-menu
                        v-model="to_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                                label="To Date"
                                 :value="to_date"
                                 readonly
                                 filled
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="to_date" no-title scrollable @input="to_menu=false, getBuys()" />
                    </v-menu>
               </span>
                </v-toolbar>
                <v-card-text>
                  <v-row>
                      <v-col cols="6">
                        <div>Per Account Transaction</div>
                      
                        <v-data-table
                     class="va-table"
                     dense
                     :headers="headers"
                     :items="buycredits"
                     >
                     <template v-slot:body="{ items }">
                         <tbody>
                         <tr v-for="(item,index) in items" :key="index">
                             <td>
                               <!-- <v-icon  small left color="warning" @click="select_item=item, state='update', form=true" >mdi-pencil</v-icon> -->
                               {{ item.user_id}}</td>
                             <td>{{ $money(item.req_amount) }} </td>
                             <td>{{ $money(valueAmount(item.amount)) }}</td>
                             <td>{{ $moment(item.req_dt).format("YYYY-MM-DD HH:mm a") }}</td>
                             <td class="text-right text-success">
                              {{item.status}}
                             </td>
                         </tr>
                         </tbody>
                     </template>
                     </v-data-table>
                      </v-col>
                      <v-col cols="6">
                          <div> Daily Summary</div>
                        <v-data-table
                     class="va-table"
                     dense
                     :headers="sumheaders"
                     :items="summaries"
                     >
                     <template v-slot:body="{ items }">
                         <tbody>
                         <tr v-for="(item,index) in items" :key="index">
                             <td>{{item.req_date}}</td>
                             <td>{{item.trans_count}}</td>
                             <td>{{$money(item.req_amount)}}</td>
                             <td class="text-success">{{$money(item.amount)}}</td>
                         </tr>
                         </tbody>
                     </template>
                     </v-data-table>
                      </v-col>
                  </v-row>
                
                </v-card-text>
              </v-card>
           </v-col>
     </v-row>
     </v-container>
     <va-cashout-confirm :show="form" :cashout="select_item" @DialogEvent="afEvent" />
   </div>
 </template>
 <script>
 import {
   mapMutations
 } from 'vuex'
   export default {
     data: () => ({
         form: false,
         from_menu: false,
         to_menu: false,
         areaform: false,
         status: 'All',
         from_date:'',
         to_date:'',
         select_item: {},
         buycredits:[],
         allcredits:[],
         summaries:[],
         headers: [
             { text: 'Acct no.',},
             { text: 'Req.',},
             { text: 'Recieved',},
             { text: 'Req. DT',},
             { text: 'Status', align:'right'}
         ],
         sumheaders: [
             { text: 'Date',},
             { text: 'Trans. No.',},
             { text: 'Req. Amount',},
             { text: 'Recieved',},
            
         ],
     }),
     beforeCreate: function () {
       if (!this.$session.exists()) {
         this.$router.push('/')
       }
     },
     created() {
        if (this.$session.exists()) {
           this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
           this.setLoggedIn(true) 
           this.from_date =  this.$moment().subtract(30, "days").format("YYYY-MM-DD")
          this.to_date =  this.$moment().format("YYYY-MM-DD")
          this.getBuys()
         }
     },
     computed:{
        windowClass() {
         if(this.$store.state.responsive){
           return "va-reponsive"
         } else {
           return "va-not-reponsive"
         }
       },
       COLLECTIONS() {
        var items = []
          for (var i = 1; i <=this.summaries.length; i++) {
            items.push(this.summaries[this.summaries.length-i])
          }
          return items
       },
       RECIEVABLE(){
        var total = this.summaries.reduce((res,item)=>res+=item.amount, 0)
        return total
       },
       TRANSCOUNT() {
        var total = this.summaries.reduce((res,item)=>{
          res+=this.$IsNum(item.trans_count)
         return res
        }, 0)
        return total
       },
       TOTALREQ() {
        var total = this.summaries.reduce((res,item)=>{
          res+=this.$IsNum(item.req_amount)
         return res
        }, 0)
        return total
       }
     },
     methods: {
       ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
       valueAmount(amount) {
         return amount //this.$IsNum(amount.toString().slice(0,-2))
       },
       afEvent() {
         this.form = false
         this.getBuys()
       },
       getBuys() {
         this.buycredits = []
         this.summaries = []
       this.$http.post("get_buy_credits_summaries", {req_date:this.from_date+"to"+this.to_date}).then(response => {
            console.log("get_buy_credits_summaries",response.data)
            this.buycredits = response.data.items
            this.summaries = response.data.sums
           }).catch(e => {
             console.log(e.data)
           })
     },
     statusColor(status){
         if(status == "Pending") {
           return "warning"
         } else if(status == "Completed") {
           return "success"
         } else {
           return "#ddd"
         }
     }
      
     }
   }
 </script>